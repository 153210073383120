<template>
  <Header :header="header"></Header>
  <ul class="container_steper" style="display:none">
    <li style="display:none">
      <div class="texta">Completa tu perfil y ayudanos a conocerte mejor (:</div>
      <span class="profileupload"><i class="icon-upoad"></i></span>
      <div class="textb">Subir foto</div>
      <div class="block-input">
        <input type="text" placeholder="Nombre de tu restaurante">
      </div>
      <div class="block-input">
        <input type="text" placeholder="Nombre de la persona de contacto">
      </div>
      <div class="block-input">
        <input type="text" placeholder="Teléfono de contacto">
      </div>
    </li>
    <li style="display:none">
      <div class="map"></div>
      <div class="block-input input_icon">
        <input type="text" placeholder="Ingrese dirección">
        <span class="icon_span">
          <i class="icon-pin"></i>
        </span>
      </div>
      <div class="texta">
        Agrega la dirección de tu restaurante para encontrar los mejores foodies cerca de tí
      </div>
    </li>
    <li style="display:none">
      <div class="texta">
        ¡Ya casi estamos!<br>Agrega tu correo electrónico y una contraseña para entrar en OnlyFoodies
        <span class="icon_lock_cont">
          <i class="icon-lock"></i>
        </span>
      </div>
      <div class="block-input">
        <input type="email" placeholder="Correo electrónico">
      </div>
      <div class="block-input input_icon">
        <input type="password" placeholder="Contraseña secreta">
        <span class="icon_span">
          <i class="icon-eye"></i>
        </span>
      </div>
      <div class="foodie2_check_btn check_fix">
        <input type="checkbox" name="durations" class="foodie2_check" value="" id="1">
        <label  for="1">
            Acepto recibir correos exlusivos del uso de la plataforma
        </label>
      </div>
    </li>
    <li class="children_center" style="display:none">
      <div class="profile_img" style="background-image: url('/img/logo.89084e8a.png');"></div>
      <div class="nametxt">Eat By Chloe</div>
      <div class="mailtxt">@eatbychloe</div>
      <div class="texta">
        Todo listo! ya estas a bordo, hora de explorar OnlyFoodies
      </div>
    </li>
  </ul>
  <div class="btn_footer" style="text-align: right;display:none">
    <button class="circle_btn" style="display:none"><i class="icon-arrowRight"></i></button>
    <button class="main_btn">COMENCEMOS!</button>
  </div>
  <div class="container_large">
    <img src="../../../../assets/logo.png" class="finalLogo">
    <div class="mailtxt">Ya eres parte de OnlyFoodies!</div>
    <div class="nametxt">Eat By Chloe</div>
    <div class="textc">
      ¿Eres un Foodie o un restaurante?
    </div>
  </div>
  <div class="btn_footer">
    <button class="main_btn" style="margin-bottom:15px">COMENCEMOS!</button>
    <button class="btn-line">COMENCEMOS!</button>
  </div>
</template>

<script>
import Header from '@/components/authenticated/Header'
export default {
  name: 'profile',
  components: {
    Header
  },
  data () {
    return {
      header: {
        type: 'single',
        title: 'Completa tu perfil'
      }
    }
  }
}
</script>

<style lang="scss">
  .btn-line{
    border: 2px solid #F2B415;
    height: 48px;
    display: inline-block;
    width: 100%;
    border-radius: 24px;
    color: #F2B415;
    font-weight: bold;
  }
  .input_icon{
    input{
      width: calc(100% - 48px) !important;
      border-right: none !important;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
    .icon_span{
      width: 48px;
      height: 48px;
      display: inline-block;
      text-align: center;
      line-height: 48px;
      border-top: 1px solid #d1d1d1;
      border-bottom: 1px solid #d1d1d1;
      border-right: 1px solid #d1d1d1;
      vertical-align: middle;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      font-size: 20px;
    }
  }
  .map{
    display: inline-block;
    height: 350px;
    width: 100%;
    background: #f1f2f0;
    margin-bottom: 20px;
  }
  .texta{
    margin: 0 auto;
    width: 285px;
  }

  .textb{
    font-size: 14px;
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
  }
  .textc{
    width: 225px;
    line-height: 1;
    margin: 20px auto;
    font-size: 16px;
  }
  .profileupload{
    width: 160px;
    height: 160px;
    display: block;
    text-align: center;
    line-height: 160px;
    background: #d1d1d1;
    border-radius: 100%;
    font-size: 32px;
    margin: 20px auto;
  }
  .icon_lock_cont{
    width: 100%;
    display: inline-block;
    font-size: 32px;
    margin: 20px 0;
  }
  @mixin optionbutton($name,$fontcolor1,$fontcolorhover,$size,$on-color,$on-fontcolor,$fontsize){
    .#{$name}_check_btn label{
        display: inline-block;
        cursor: pointer;
        color:$fontcolor1;
        transition: all .5s ease;
        line-height: 30px;
        margin-right: 6px;
        min-width: 30px;
        border-radius: 24px;
        padding: 0 10px 0 25px;
        font-size: $fontsize;
        text-align: center;
        position: relative;
        &:hover {
            color: $fontcolorhover;
            &:before {
                border: 1px solid $fontcolorhover;
            }
        }
        &:before {
            display: inline-block;
            width: 20px;
            height: 20px;
            position: absolute;
            background: transpatent;
            border: 1px solid $fontcolor1;
            top: 5px;
            transition: all .5s;
            left: 0px;
            font-family: 'icomoon' !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 16px;
            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\e906";
            color: $on-fontcolor;
            border-radius:100%;
            //@extend [class^="icon-"];
            //@extend .icon-checkmark:before;
        }
    }
    .#{$name}_radio_btn label{
        &:before {
            border-radius: 100%;
        }
    }
    .#{$name}_check{
        display: none;
        &:checked + label:before {
            border: 10px solid $on-color;
            border: 2px solid $on-color;
            background: $on-color;
        }
        /* &:checked + label {
          padding: 0 10px 0 25px;
        } */
    }
  }
  @include optionbutton(foodie2,#1D1C1B,rgba(#1D1C1B, .4),14px,#f2b415,white,12px);
  .circle_btn{
    background: #F2B415;
    height: 48px;
    display: inline-block;
    width: 48px;
    border-radius: 24px;
    color: white;
    font-size: 24px;
  }
  .container_steper{
    padding: 15px;
    height: calc(100vh - 172px);
    display: table;
    width: 100%;
  }

  .check_fix{
    text-align: left;
    width: 250px;
    label{
      line-height: 1;
      text-align: left;
      &:before{
        top: 2px !important;
        text-align: center;
      }
    }
  }
  .profile_img{
    width: 80px;
    height: 80px;
    background-size: cover;
    border-radius: 100%;
    margin: 0 auto;
    background-color: #cccccc;
  }
  .nametxt{
    font-size: 18px;
    line-height: 24px;
  }
  .mailtxt{
    font-size: 14px;
    opacity: .5;
  }
  .children_center{
    display: table-cell;
    vertical-align: middle;
  }
  .finalLogo{
    margin: 70px auto;
  }
  .container_large{
    display: inline-block;
    height: calc( 100vh - 235px);
    width: 100%;
  }
</style>
